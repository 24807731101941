/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import './assets/css/navbar.css'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from './assets/images/logobg.png';
import {Link} from "react-router-dom";







export default function Navigationbar(){


   
    return(

            <>
                <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="fixed-top ">
                    <Container>
                        <Navbar.Brand className="logo" href="/">
                            <img className="logo-style" src={logo} alt=""/>
                            Dentemy
                        </Navbar.Brand>

                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto text-center navbar-menus " id="mobileview-menu-style">
                            <Nav.Link href="/">
                                <Link to="/" class="nav-menu">
                                    Home
                                </Link>
                            </Nav.Link>
                            <Nav.Link >
                                <Link to="/aboutus" class="nav-menu">
                                    About Us
                                </Link>
                            </Nav.Link>
                            <Nav.Link >
                                <Link to="/contactus" class="nav-menu">
                                    Contact Us
                                </Link>
                            </Nav.Link>
                            <Nav.Link >
                                <Link to="/faqs" class="nav-menu">
                                   FAQS
                                </Link>
                            </Nav.Link>
                        </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </>
    )

}