import React from 'react';
import Navigationbar from "../../components/navbar/navbar";
import './assets/css/home.css';
import bg2 from './assets/images/hero-bg-shape-1.svg';
import {Link} from "react-router-dom";
import AOS from 'aos';
import "aos/dist/aos.css";
import Table from 'react-bootstrap/Table';
import Footercomponent from "../../components/footer/footer";
import data from './data/homedata.json';




export default function Home(){


    React.useEffect(() => {
        AOS.init({
            duration : 2000
        });


    }, []);


    return(
        <>
            <section>
                <Navigationbar/>
            </section>
            <section className="home-about-dentemy-content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="about-text">
                                <h1 className="about-text-title">{}<span>{}</span></h1>
                                <h1 className="about-text-title">{data[0].section1.title}<span>{data[0].section1.companyName}</span></h1>
                                <h4 className="about-text-para">{data[0].section1.subTitle}</h4>
                                <strong>{data[0].section1.quote}</strong>
                                <div className="social-media-app-imgs">
                                    <Link to="#">
                                        <img className="applestore-img"  data-aos="fade-left" src={process.env.PUBLIC_URL + '/images/homeimages/'+ data[0].section1.image1} alt=""/>
                                    </Link>
                                    <Link to="#">
                                        <img className="playstore-img" data-aos="fade-right" src={process.env.PUBLIC_URL + '/images/homeimages/'+ data[0].section1.image2} alt=""/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="about-img">
                                <img className="promoimg-style" data-aos="fade-left" src={process.env.PUBLIC_URL + '/images/homeimages/'+ data[0].section1.image3} alt=""/>
                                <img className='gifimg-style' id="gifimg-style-mobile" data-aos="zoom-in"   data-aos-delay="1000" src={process.env.PUBLIC_URL + '/images/homeimages/'+ data[0].section1.image4} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section className="secure-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="secure-style">
                                <h1 className="secure-text-title">{data[0].section2.title}</h1>
                                <p className="secure-section-para">
                                    {data[0].section2.para}
                                </p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <img className="security-img-style" src={process.env.PUBLIC_URL +'/images/homeimages/'+data[0].section2.image1 } alt=""/>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row secure1-size">
                                            <div className="col-md-2">
                                                <img className='secure-section-icon-bg' data-aos="zoom-in" data-aos-delay="1000" src={process.env.PUBLIC_URL +'/images/homeimages/'+data[0].section2.image2 } alt=""/>
                                                <i className="fa fa-lock secure-section-icon1" aria-hidden="true"  data-aos="zoom-in" data-aos-delay="1000"></i>
                                            </div>
                                            <div className="col-md-10">
                                                <h4 className="secure1">Tech that keeps you safe</h4>
                                            </div>
                                        </div>
                                        <div className="row secure2-size">
                                            <div className="col-md-2">
                                                <img className='secure-section-icon-bg' data-aos="zoom-in" data-aos-delay="1000" src={process.env.PUBLIC_URL +'/images/homeimages/'+data[0].section2.image2 } alt=""/>
                                                <i className="fa fa-commenting-o secure-section-icon2" aria-hidden="true"  data-aos="zoom-in" data-aos-delay="1200"></i>
                                            </div>
                                            <div className="col-md-10">
                                                <h4 className="secure1">Humans on hand</h4>
                                            </div>
                                        </div>
                                        <div className="row secure3-size">
                                            <div className="col-md-2">
                                                <img className='secure-section-icon-bg' data-aos="zoom-in" data-aos-delay="1000" src={process.env.PUBLIC_URL +'/images/homeimages/'+data[0].section2.image2 } alt=""/>
                                                <i className="fa fa-check secure-section-icon3" aria-hidden="true"  data-aos="zoom-in" data-aos-delay="1400"></i>
                                            </div>
                                            <div className="col-md-10">
                                                <h4 className="secure1">Strong privacy</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="home-section-bg" data-aos="fade-up" data-aos-delay="1000">
                <div className="bottom-img-absolute">
                    <img src={bg2} alt=""/>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="about-dentemy-headline">
                                    <h1 className="connect-us-text"
                                        data-aos="fade-right"
                                    >
                                        {data[0].section3.title}
                                    </h1>
                                    <h4 className="connect-us-text-para"
                                        data-aos="fade-left"
                                    >
                                        {data[0].section3.subtitle}
                                    </h4>
                                    <button type="button"
                                            className="btn btn-primary btn-lg btn-primary-subscribe btn-subscribe"
                                            data-aos="fade-up"
                                    >
                                        {data[0].section3.buttonValue}
                                    </button>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mobile-img">
                                <img className="mobile-img-style"
                                     data-aos="zoom-in"
                                     data-aos-delay="2000"
                                     data-aos-once="false"
                                     src={process.env.PUBLIC_URL + '/images/homeimages/'+ data[0].section3.image1} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="feature-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="features-area">
                                <Table responsive="sm">
                                    <thead>
                                        <tr>

                                            {
                                                data[0].section4.column.map((item) => (
                                                    <th className="amount-in-rupees" key={item.subscribeType}>{item.subscribeType}</th>
                                                ))
                                            }
                                        </tr>
                                        <tr>

                                            {
                                                data[0].section4.column.map((item) => (
                                                    <th className="sub-title" key={item.subscribeColTitle}>{item.subscribeColTitle}</th>
                                                ))
                                            }

                                        </tr>
                                    </thead>
                                    <tbody>
                                    <tr >
                                        <th>
                                            {data[0].section4.priceDiscription.subscribeRowTitle}

                                            <h6 className="sub-title">

                                                {data[0].section4.priceDiscription.subscribeRowTitleDescription}
                                            </h6>
                                        </th>
                                    {
                                        data[0].section4.column.map((item) => (
                                            (item.price !== "") ?
                                                <td key={item.price}>
                                                    <br/>
                                                    <h4 className="percentage">{item.price}</h4>
                                                </td>

                                                : ""
                                        ))
                                    }
                                    </tr>


                                       {
                                           data[0].section4.row.map((item) => (
                                               <tr>
                                               <th>
                                                   {item.subscribeRowTitle}
                                                   <br/>
                                                   <h6 className="sub-title">
                                                       {item.subscribeRowTitleDescription}
                                                       <span>
                                                    {item.subscribeRowTitleDescriptionQuotes}
                                                </span>
                                                   </h6>
                                               </th>
                                                   {
                                                       item.icon.map((items) => (
                                                       <td>
                                                       <h4 className="tick-style"><i className={"fa " + items} aria-hidden="true"></i></h4>
                                                       </td>
                                                       ))
                                                   }
                                               </tr>

                                           ))
                                       }


                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="letsget-started-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="letsget-started">
                                <h5 className="letsget-started-title"> {data[0].section5.quotes}</h5>
                                <div className="social-media-app-imgs-in-letsget-started">
                                    <Link to="#">
                                        <img className="applestore-img"  data-aos="fade-left" src={process.env.PUBLIC_URL + '/images/homeimages/'+ data[0].section5.image1} alt=""/>
                                    </Link>
                                    <Link to="#">
                                        <img className="playstore-img" data-aos="fade-right" src={process.env.PUBLIC_URL + '/images/homeimages/'+ data[0].section5.image2} alt=""/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <Footercomponent/>
            </section>
        </>
    )
}