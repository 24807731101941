import React from 'react';
import Navigationbar from "../../components/navbar/navbar";
import './assets/css/aboutus.css';
import ticicon from './assets/images/ticicon.svg';
import AOS from "aos";
import Footercomponent from "../../components/footer/footer"

import data from "./data/aboutdata.json";

export default function Aboutus(){

    React.useEffect(() => {
        AOS.init({
            duration : 2000
        });
    }, []);


    return (
        <>
            <section>
                <Navigationbar/>
            </section>
            <section className="aboutus-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="aboutus-content1">
                                <h1 className="aboutus-title" data-aos="fade-right" >{data[0].section1.title}</h1>
                                <p className="aboutus-content-para" data-aos="fade-right" >
                                    {data[0].section1.aboutContent}
                                </p>
                                <h1 className="aboutus-quotes" data-aos="fade-up" >
                                    {data[0].section1.quotes}
                                </h1>
                                <hr className="hr-line-style" data-aos="fade-up" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="aboutus-content2">
                                <img className="aboutus-img" data-aos="fade-left"  src={process.env.PUBLIC_URL + '/images/aboutimages/'+ data[0].section1.image} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="aboutus-middle-content-section" data-aos="fade-right" >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="team-img-area row">
                                <h2 className="aboutus-middle-title">
                                    {data[0].section2.title1}
                                    <br/>
                                    {data[0].section2.title2}
                                </h2>
                                <div className="aboutus-content-points col-md-1">
                                    <ul>
                                        <li>
                                            <img className={data[0].section2.icon} data-aos="fade-right"  src={ticicon} alt=""/>
                                        </li>
                                        <li>
                                            <img className={data[0].section2.icon} data-aos="fade-right" src={ticicon} alt=""/>
                                        </li>
                                        <li>
                                            <img className={data[0].section2.icon} data-aos="fade-right" src={ticicon} alt=""/>
                                        </li>
                                        <li>
                                            <img className={data[0].section2.icon} data-aos="fade-right"  src={ticicon} alt=""/>
                                        </li>
                                    </ul>
                                </div>
                                <div className="aboutus-content-points col-md-11 ">
                                    <ul>
                                        <li>
                                            <p className="aboutus-point">{data[0].section2.feature1}</p>
                                        </li>
                                        <li>
                                            <p className="aboutus-point">{data[0].section2.feature2}</p>
                                        </li>
                                        <li>
                                            <p className="aboutus-point">{data[0].section2.feature3}</p>
                                        </li>
                                        <li>
                                            <p className="aboutus-point">{data[0].section2.feature4}</p>
                                        </li>
                                    </ul>
                                </div>
                                <div className="gif-img-aboutus">
                                    <img className="aboutus-gif-img" data-aos="fade-left"  src={process.env.PUBLIC_URL + '/images/aboutimages/'+ data[0].section2.image} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-footer-section">
                <Footercomponent/>
            </section>
        </>
    )
}