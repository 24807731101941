import {
    BrowserRouter,
    Routes,
    Route
} from 'react-router-dom'
import Home from "./pages/home/home";
import Aboutus from "./pages/aboutus/aboutus";
import Contactus from "./pages/contactus/contactus";
import Faqs from "./pages/faqs/faqs";


function App() {
  return (
    <div>
        <BrowserRouter>
            <Routes>
                <Route exact path="/"  element={<Home/>}></Route>
                <Route path="/aboutus" element={<Aboutus/>}></Route>
                <Route path="/contactus" element={<Contactus/>}></Route>
                <Route path="/faqs" element={<Faqs/>}></Route>
            </Routes>
        </BrowserRouter>

    </div>
  );
}

export default App;
