import React from 'react';
import './assets/css/footer.css';
import data from './data/footerdata.json';

export default function Footercomponent(){
    return (
        <>
            <section id="footer">
                <div className="container">
                    <div className="row text-center text-xs-center text-sm-left text-md-left footer-content">
                        <div className="col-xs-12 col-sm-4 col-md-4 footer-about-company">
                            <h1 className="about-dentemy">
                                <img className="footer-logo-style" src={process.env.PUBLIC_URL + '/images/footerimages/'+ data[0].section1.image}  alt=""/>
                                {data[0].section1.title}
                            </h1>
                            <p className="about-dentemy-para">
                                {data[0].section1.aboutDentemy}
                            </p>
                        </div>
                        <div className="col-xs-12 col-sm-4 col-md-4 footer-about-company">
                            <h5>{data[0].section2.title}</h5>
                            <ul className="list-unstyled quick-links">
                                <li>
                                    <a href="https://www.fiverr.com/share/qb8D02">
                                        <i className="fa fa-angle-double-right"></i>
                                        {data[0].section2.link1}
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.fiverr.com/share/qb8D02">
                                        <i className="fa fa-angle-double-right"></i>
                                        {data[0].section2.link2}
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.fiverr.com/share/qb8D02">
                                        <i className="fa fa-angle-double-right"></i>
                                        {data[0].section2.link3}
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.fiverr.com/share/qb8D02">
                                        <i className="fa fa-angle-double-right"></i>
                                        {data[0].section2.link4}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-xs-12 col-sm-4 col-md-4 footer-about-company">
                            <h5>{data[0].section3.title}</h5>
                            <ul className="list-unstyled quick-links">
                                <li>
                                    <a href="mailto:contact@dentemy.com">
                                        <i className="fa fa-envelope" aria-hidden="true"></i>
                                        {data[0].section3.contactMail}
                                    </a>
                                </li>
                                <li>
                                    <a href="tel:1234567890">
                                        <i className="fa fa-phone" aria-hidden="true"></i>
                                        {data[0].section3.contactPhone}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-5">
                            <ul className="list-unstyled list-inline social text-center">
                                <li className="list-inline-item">
                                    <a href="https://www.fiverr.com/share/qb8D02">
                                        <i className={data[0].section4.iconFb}></i>
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="https://www.fiverr.com/share/qb8D02">
                                        <i className={data[0].section4.iconTwitter}></i>
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="https://www.fiverr.com/share/qb8D02">
                                        <i className={data[0].section4.iconInstagram}></i>
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="https://www.fiverr.com/share/qb8D02">
                                        <i className={data[0].section4.iconGooglePlus}></i>
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="https://www.fiverr.com/share/qb8D02" target="_blank">
                                        <i className={data[0].section4.iconMail}></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <hr/>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-center text-white">
                            <p>
                                <u>
                                    <a href="https://www.nationaltransaction.com/">
                                        {data[0].section5.companyTitle}
                                    </a>
                                </u>
                                {data[0].section5.aboutCompany}
                            </p>
                            <p className="h6">
                                {data[0].section5.copyRight}
                                <a className="text-green ml-2"
                                   href="https://www.sunlimetech.com"
                                   target="_blank">
                                    {data[0].section5.byThisCompany}
                                </a>
                            </p>
                        </div>
                        <hr/>
                    </div>
                </div>
            </section>
        </>
    )
}