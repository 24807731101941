import React from 'react';
import './assets/css/faqs.css';
import Navigationbar from "../../components/navbar/navbar";
import Footercomponent from "../../components/footer/footer";
import AOS from "aos";
import data from "./data/faqdata.json";



export default function Faqs() {

    React.useEffect(() => {
        AOS.init({
            duration : 2000
        });
    }, []);


    return (
        <>
            <section>
                <Navigationbar/>
            </section>
            <section className="faq-section">
                <div className="container">

                    <div className="row">
                        <div className="col-md-12">
                            <div  data-aos="fade-up">
                                <img className="faq-img" src={process.env.PUBLIC_URL+'/images/faqimages/'+ data.pageContent[0].faqImage} alt=""/>
                            </div>
                        </div>
                        <h1 className="faq-title "  data-aos="fade-down">{data.pageContent[0].faqTitle}</h1>
                        <div className="col-md-12">
                            <div className="accordion" id="accordionExample"  data-aos="fade-down">
                                {data.faqRows.map((item) => (
                                    <div className="card">
                                        <div className="card-header" id={"heading" + item.WhichOne }>
                                            <h2 className="mb-0">
                                                <div className="row">
                                                    <div className="col-md-1">
                                                        <button className="btn btn-link faq-question" type="button" data-toggle="collapse"
                                                                data-target={"#collapse" + item.WhichOne} aria-expanded={item.FirstTimeExanpand} aria-controls={"collapse" + item.WhichOne}>
                                                            {item.id}
                                                        </button>
                                                    </div>
                                                    <div className="col-md-11 question-style">
                                                        {item.question}
                                                    </div>
                                                </div>
                                            </h2>
                                        </div>
                                        <div id={"collapse" + item.WhichOne} className={"collapse "  } aria-labelledby={"heading" + item.WhichOne}
                                             data-parent="#accordionExample">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-1">

                                                    </div>
                                                    <div className="col-md-8">
                                                        {item.answer}
                                                     </div>
                                                    <div className="col-md-3">
                                                        <img className="dental-img" src={process.env.PUBLIC_URL + "/images/faqimages/" + item.image} alt=""/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="faq-footer-section">
                <Footercomponent/>
            </section>

        </>
    )
}





